import React from "react";
// import { announcements, cardContainer } from "../styles/home.module.css"
import { AnnouncementCard } from "../components/AnnouncementCard";
export const AnnouncementContainer = props =>
{
  const { data } = props;
  return (
    <div className={`px-4 sm:px-8 md:px-12 lg:px-16 pt-16`}>
      <p className="mb-20 md:text-4xl text-3xl md:font-medium">
        Where new stuff keeps happening
      </p>
      <div
        className={`flex-wrap flex items-center justify-center gap-8 mb-4 md:mb-10`}
      >
        {data.map(d => (
          <AnnouncementCard data={d} />
        ))}
      </div>
    </div>
  );
};
